select {
  @apply pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}

.form-error::before,
.form-error::after {
  border: solid transparent;
  bottom: 100%;
  content: '';
  height: 0;
  left: 10px;
  pointer-events: none;
  width: 0;
  position: absolute;
}

.form-error::before {
  --tw-bg-opacity: 1;
  border-bottom-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  border-width: 6px;
  margin-left: -1px;
}

.input.with_label.toggle .label_input {
  display: flex;
  font-size: 14px;
  align-items: center;
}
