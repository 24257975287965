.display-name {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;

  bdi {
    min-width: 0;
  }

  &__account {
    position: relative;
    font-weight: 600;
    font-size: 14px;
  }
}
