.thread {
  @apply bg-white dark:bg-primary-900;

  &__status {
    @apply relative pb-4;

    .status__wrapper {
      @apply shadow-none p-0;
    }
  }

  .status__content-wrapper {
    @apply pl-[calc(42px+12px)] rtl:pl-0 rtl:pr-[calc(42px+12px)];
  }
}
