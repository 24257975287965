@use 'sass:math';

// TYPEOGRAPHY MIXINS

// Use these mixins to define font-size and line-height
// html and body declaration allows developer to pass px value as argument
// Rendered css will default to "rem" and fall back to "px" for unsupported browsers
@mixin font-size($size) {
  $rem: math.div($size, 10);
  $px: $size;
  font-size: #{$px + 'px'};
  font-size: #{$rem + 'rem'};
}
