.modal-root__modal {
  pointer-events: auto;
  display: flex;
  z-index: 9999;
  max-height: 100%;
  overflow-y: hidden;
}

.media-modal {
  .audio-player.detailed,
  .extended-video-player {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .audio-player {
    max-width: 800px;
    max-height: 600px;
  }

  .extended-video-player {
    width: 100%;
    height: 100%;

    video {
      @apply max-w-full max-h-[80%];
    }
  }
}

.error-modal {
  @apply text-gray-900;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 80vw;
    max-width: 520px;
    max-height: 420px;
    position: relative;
    text-align: center;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0;
      user-select: text;
    }
  }

  &__footer {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    padding: 25px;

    & > div {
      min-width: 33px;
    }
  }
}

.actions-modal {
  @apply flex-col relative text-gray-400 overflow-hidden w-full max-w-lg m-auto bg-white dark:bg-gray-900 shadow-xl rounded-2xl;
  max-height: calc(100vh - 3rem);

  &__item-label {
    font-weight: 500;
  }

  .dropdown-menu__separator {
    @apply block m-2 h-[1px] bg-gray-200 dark:bg-gray-600;
  }

  &__status {
    @apply overflow-y-auto max-h-[300px];
  }

  ul {
    @apply my-2 flex-shrink-0 overflow-y-auto;
    max-height: calc(100vh - 147px);

    &.with-status { max-height: calc(80vh - 75px); }

    li:not(:empty) {
      a,
      button {
        @apply flex items-center px-4 py-3 text-gray-700 dark:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800 focus:bg-gray-100 dark:focus:bg-primary-800 no-underline text-left;

        &.destructive {
          @apply text-danger-600 dark:text-danger-400;
        }

        .svg-icon:first-child {
          @apply min-w-[1.25rem] w-5 h-5;

          svg {
            stroke-width: 1.5;
          }
        }
      }

      button[type='button'] {
        @apply w-full justify-center text-center;
      }
    }
  }
}

.reply-mentions-modal__accounts {
  display: block;
  flex-direction: row;
  flex: 1;
  overflow-y: auto;
  min-height: 300px;
}

.remote-interaction-modal {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    button {
      align-self: flex-end;
    }
  }

  &__divider {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 -10px;

    &::before,
    &::after {
      @apply border-b border-gray-300 dark:border-gray-600;
      content: '';
      flex: 1;
    }
  }

  @media screen and (max-width: 895px) {
    margin: 0;
    border-radius: 6px;
    height: unset !important;
    width: 440px !important;
  }

  @media screen and (max-width: 480px) {
    width: 330px !important;
  }
}
