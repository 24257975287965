.search {
  position: relative;
}

.search__icon {
  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus {
    outline: 0 !important;
  }

  .svg-icon {
    @apply right-4 rtl:left-4 rtl:right-auto text-gray-400;
    @include font-size(16);
    cursor: default;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 18px;
    height: 18px;
    opacity: 0;
    pointer-events: none;

    &.active {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .svg-icon--search.active {
    pointer-events: none;
  }

  .svg-icon--backspace {
    cursor: pointer;
    width: 22px;
    height: 22px;
  }
}
