.react-datepicker__input-container input {
  // display: block;
  // box-sizing: border-box;
  // width: 100%;
  // margin: 0;
  // background: transparent;
  // color: var(--primary-text-color);
  // padding: 10px;
  // font-family: inherit;
  // font-size: 16px;
  // resize: vertical;
  // border: 0;
  // outline: 0;

  // &:focus {
  //   outline: 0;
  // }

  // @media screen and (max-width: 600px) {
  //   font-size: 16px;
  // }
}

.autosuggest-emoji {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  line-height: 18px;
  font-size: 14px;
}

.autosuggest-emoji img {
  display: block;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
