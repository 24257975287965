// Language codes that uses CJK fonts
/* stylelint-disable-next-line value-keyword-case -- locale filenames */
$cjk-langs: ja, ko, zh-CN, zh-HK, zh-TW;

// CSS variables
// NOTE: Prefer CSS variables whenever possible.
// They're future-proof and more flexible.
:root {
  --thumb-navigation-height: calc(60px + env(safe-area-inset-bottom));
}
