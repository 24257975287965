em-emoji-picker {
  --rgb-background: 255 255 255;
  --rgb-accent: var(--color-primary-600);
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark em-emoji-picker {
  --rgb-background: var(--color-primary-900);
}
