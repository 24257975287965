.crypto-address {
  @apply flex flex-col p-5;

  &__head {
    @apply flex items-center mb-1.5;
  }

  &__title {
    @apply font-bold;
  }

  &__icon {
    @apply flex items-start justify-center w-6 mr-2.5;

    img {
      @apply w-full;
    }
  }

  &__actions {
    @apply flex ml-auto;

    a {
      @apply text-gray-400 ml-2;
    }

    .svg-icon {
      @apply h-4.5 w-4.5;
    }
  }

  &__note {
    @apply mb-2.5;
  }

  &__qrcode {
    @apply flex items-center justify-center mb-3 p-2.5;
  }

  &__address {
    @apply mt-auto;
  }
}

.crypto-donate-modal .crypto-address {
  @apply p-0;
}
