[data-reach-menu-popover] {
  @apply origin-top-right rtl:origin-top-left absolute mt-2 rounded-md shadow-lg bg-white dark:bg-gray-900 dark:ring-2 dark:ring-gray-800 focus:outline-none z-[1003];
}

[data-reach-menu-button] {
  @apply focus:ring-primary-500 focus:ring-2 focus:ring-offset-2;
}

div:focus[data-reach-menu-list] {
  outline: none;
}

[data-reach-menu-item][data-selected] {
  @apply bg-gray-100 dark:bg-gray-800;
}

[data-reach-menu-list] {
  @apply py-1;
}

[data-reach-menu-item],
[data-reach-menu-link] {
  @apply block px-4 py-2.5 text-sm text-gray-700 dark:text-gray-500 cursor-pointer;
}

[data-reach-menu-link] {
  @apply hover:bg-gray-100 dark:hover:bg-gray-800;
}

[data-reach-menu-item][data-disabled],
[data-reach-menu-link][data-disabled] {
  @apply opacity-25 cursor-default;
}
