body {
  @apply antialiased;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body.with-modals {
  @apply overflow-hidden;
}

// Note: this is needed for React HotKeys performance. Removing this
// will cause severe performance degradation on Safari.
div[tabindex='-1']:focus {
  outline: 0;
}

::selection {
  @apply bg-primary-600 text-white;
}

noscript {
  text-align: center;
}

.emojione {
  @apply w-4 h-4 -mt-[0.2ex] mb-[0.2ex] inline-block align-middle object-contain;
}

// Virtuoso empty placeholder fix.
// https://gitlab.com/petyosi/soapbox-fe/-/commit/1e22c39934b60e5e186de804060ecfdf1955b506
div[data-viewport-type='window'] {
  position: static !important;
}
