@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .break-word-nested > p {
    word-break: break-word;
  }

  .shadow-inset {
    box-shadow: inset 0 0 0 1px rgb(255 255 255 / 10%);
  }

  .truncate-child > * {
    @apply truncate;
  }

  .d-screen {
    height: 100vh; /* Backwards compatibility */
    /* stylelint-disable-next-line unit-no-unknown */
    height: 100dvh;
  }

  .bg-gradient-light {
    background: linear-gradient(
      115deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      rgba(var(--color-gradient-start) / 0.1) 35%,
      rgba(var(--color-gradient-end) / 0.1) 70%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .bg-gradient-dark {
    background: linear-gradient(
      115deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 30%,
      rgba(var(--color-gradient-start) / 0.1) 45%,
      rgba(var(--color-gradient-start) / 0.2) 55%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .bg-gradient-sm {
    background: linear-gradient(
      112deg,
      rgba(var(--color-gradient-start) / 0.1) 0%,
      rgba(var(--color-gradient-end) / 0.1) 50%
    );
  }
}
