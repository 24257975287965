.svg-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  svg {
    // Apparently this won't skew the image as long as it has a viewbox
    width: 100%;
    height: 100%;
    transition: 0.2s;
  }
}

.icon-button > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
